import Input from 'app/components/Input';
import { InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import React from 'react';

export const MuiCurrencyFormat = (props) => {
  const { onChange, value, ...rest } = props;

  return (
    <NumberFormat
      customInput={Input}
      {...rest}
      value={value}
      thousandSeparator={true}
      decimalScale={2}
      onValueChange={(target) => {
        if (onChange) onChange(target.floatValue);
      }}
      isNumericString
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">$</InputAdornment>
        ),
        inputMode: 'numeric',
      }}
    />
  );
};
