export const functions = [
  {
    value: 'none',
    label: 'Selecciona una opción',
  },
  {
    value: 'UpdateDateSPEIODS',
    label: 'Actualizar fecha de SPEI ODS',
    description:
      'Actualiza la fecha de pago por medio de SPEI de las órdenes de servicio.',
  },
  {
    value: 'UpdateDateSPEIODP',
    label: 'Actualizar fecha de SPEI ODP',
    description:
      'Actualiza la fecha de pago por medio de SPEI de las órdenes de pago.',
  },
  {
    value: 'updateStatusODS',
    label: 'Actualizar status de ODS',
    description:
      'Cambia el estatus de las ordenes de servicio. No aplica con ordenes ya pagadas.',
  },
  {
    value: 'updateStatusODP',
    label: 'Actualizar status de ODP',
    description:
      'Cambia el estatus de las órdenes de pago. No aplica con ordenes ya pagadas.',
  },
  {
    value: 'ODSBilled',
    label: 'Facturar ODS por DMS',
    description:
      'Envía información de orden para facturar por medio de Business PRO o magik.',
  },
  {
    value: 'ODSSPEI',
    label: 'Pago SPEI ODS',
    description:
      'Aplicar pagos de ordenes de servicio con el método de pago de SPEI.',
  },
  {
    value: 'ODPSPEI',
    label: 'Pago SPEI ODP',
    description:
      'Aplicar pagos de ordenes de pago con el método de pago de SPEI.',
  },
  {
    value: 'BPGetOrder',
    label: 'Obtener Orden Business Pro ',
    description:
      'Modifica datos de ordenes con información actualizada desde Business PRO, también trae órdenes que aún no se visualizan en karlo pero ya están en el DMS.',
  },
  {
    value: 'QuiterGetOrder',
    label: 'Obtener Orden Quiter',
    description:
      'Actualiza datos de ordenes con información actualizada desde Quiter, también trae órdenes que aún no se visualizan en karlo pero ya están en el DMS.',
  },
  {
    value: 'removeCommission',
    label: 'Quitar Comisión',
    description: 'Remueve la comisión cargada en pagos de SPEI.',
  },
  {
    value: 'updatePassword',
    label: 'Actualizar contraseña',
    description:
      'Actualiza la contraseña del usuario utilizando únicamente el dato de correo y agencia.',
  },
  {
    value: 'superadmins',
    label: 'Crear super administrador de agencia',
    description: '',
  },
  {
    value: 'updateAmountODS',
    label: 'Modificar monto ODS',
    description: '',
  },
  {
    value: 'updateInvoiceData',
    label: 'Actualizar datos de factura',
    description: '',
  },
  {
    value: 'refund',
    label: 'Realizar devolución',
    description: '',
  },
  {
    value: 'deposits',
    label: 'Importar excel de depósitos',
    description: '',
  },
  {
    value: 'usability',
    label: 'Reporte de Usabilidad',
    description: '',
  },
  {
    value: 'oxxoPaymentODS',
    label: 'Aplicar pago OXXO a ODS',
    description: '',
  },
  {
    value: 'oxxoPaymentODP',
    label: 'Aplicar pago OXXO a ODP',
    description: '',
  },
  {
    value: 'testAffiliation',
    label: 'Prueba de afiliación',
    description: '',
  },
  {
    value: 'searchSpei',
    label: 'Buscar SPEI',
    description:
      'Ingresa una clabe interbancaria para buscar el SPEI.',
  },
  /* {
    value: 'downloadReportQuiter',
    label: 'Descargar Reporte Quiter',
    description: 'Descargar Reporte Quiter',
  }, */
];
