import React, { useEffect, useState } from 'react';

import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  Typography,
  makeStyles,
  Select,
} from '@material-ui/core';

import { Add, Delete } from '@material-ui/icons';

import { IVA, defaultComissions } from 'app/config/monthsTaxes';

import Input from 'app/components/Input';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import { RHFMUISelect } from 'app/components/RHF';
import ShouldItRender from '../../../../components/ShouldItRender';

import {
  emailRegex,
  formatNumber,
  getNested,
  roundValue,
} from 'app/utils';

import { closeModals } from '../../../modals/modalsSlice';
import { createPaymentOrder } from '../../paymentOrdersSlice';
import { getAgency } from '../../../agencies/agenciesSlice';

import {
  makeSelectAgenciesFromdb,
  makeSelectAgencyDetailsFromState,
} from 'app/features/agencies/selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import { selectPaymentOrders } from 'app/features/paymentOrders/selectors';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import {
  getOrdersType,
} from 'app/features/calculator/containers/Calculator2023/calculator2023Slice';
import { useDispatch } from 'react-redux';
import { typeOfPromotionMap } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  switch: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldAddProducts: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  productsContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButtonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formGroupSwitch: {
    alignItems: 'center',
  },
}));

const AddPaymentOrder = (props) => {
  const classes = useStyles();
  const {
    paymentOrders,
    agencies,
    agencyDetails,
    createPaymentOrder,
    role,
    history,
  } = props;
  const dispatch = useDispatch();
  const { auth } = useSelector((auth) => auth);
  const { user } = auth;

  const [payItems, setPayItems] = useState([
    { concepto: '', total: 0 },
  ]);
  const [localFormData, setLocalFormData] = useState(null);
  const [showWarningTotal, setShowWarningTotal] = React.useState(
    false,
  );
  const [
    showMinimunTotalWarning,
    setMinimunTotalWarning,
  ] = React.useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [agencySelected, setAgencySelected] = useState('');
  const [typeOfOrders, setTypeOfOrders] = useState([]);
  const [selectedOrderType, setOrderType] = useState('');
  const ordersType = useSelector((state) => state?.calculatorData?.ordersType);

  useEffect(() => {
    props.setLeftSideComponent(
      <h3 className="kt-subheader__title">Añadir orden de pago</h3>,
    );
    if (role !== 'Super Admin') {
      props.getAgency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agencyID) {
      for (let agency of agencies) {
        if (agency.id === agencyID) {
          setSelectedAgency(agency);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyID]);

  useEffect(() => {
    if (agencySelected) {
      dispatch(getOrdersType({agencyID: agencySelected.id}));
    }else if (user?.agencyID) {
      dispatch(getOrdersType({agencyID: user?.agencyID}));
    }
  }, [agencySelected]);
  useEffect(() => {
    if(ordersType === null || !ordersType) return;
    const typeOrders = ordersType.output.filter(type => type.isActive && type.type === 'odp').map(type => type.typeOrder);
    setTypeOfOrders(typeOrders);
  }, [ordersType]);

  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: {
      provisional: false,
    },
  });
  const minimumTotal =
    agencyDetails?.minimunTotal || selectedAgency?.minimunTotal;

  const type = watch('type');
  const agencyID = watch('agencyID');

  const handleCount = () => {
    let tempPayItems = [...payItems, { concepto: '', total: '' }];
    setPayItems(tempPayItems);
  };

  const handleDelete = (index) => {
    let tempPayItems = [...payItems];
    tempPayItems.splice(index, 1);
    setPayItems(tempPayItems);
  };

  const handleEditItemConcepto = (index, concepto) => {
    let tempPayItems = [...payItems];
    tempPayItems[index].concepto = concepto;
    setPayItems(tempPayItems);
  };

  const handleEditItemTotal = (index, total) => {
    let tempPayItems = [...payItems];
    tempPayItems[index].total = total ? parseFloat(total) : '';
    setPayItems(tempPayItems);
  };

  const handleCloseModals = (redirect) => {
    props.closeModals({ history, redirect });
  };

  const handleGetTotal = () => {
    return payItems.reduce((acc, item) => (acc += item.total), 0);
  };

  const handleValidateTotal = () => {
    const total = handleGetTotal();
    return total >= 11;
  };

  const handleTotalExceedsMinimunTotal = () => {
    const total = handleGetTotal();
    return minimumTotal > 0 && total > minimumTotal;
  };

  const handleNewTotalExceeds = () => {
    const total = handleGetTotal();
    return total - minimumTotal;
  };

  const toggleWarningTotal = () => {
    setShowWarningTotal(!showWarningTotal);
  };

  const toggleMinimunTotalWarning = (formData) => {
    setMinimunTotalWarning(!showMinimunTotalWarning);
    setLocalFormData(formData);
  };

  const getBaseComission = (type) => {
    switch (type) {
      case 'american-express':
        const agencyAmexComission =
          agencyDetails?.amexCardComission ||
          selectedAgency?.amexCardComission;
        return agencyAmexComission || defaultComissions['amex'];
      default:
        const agencyComission =
          agencyDetails?.cardComission ||
          selectedAgency?.cardComission;
        return (
          agencyComission || defaultComissions['visa/mastercard']
        );
    }
  };

  const getNewMinimunTotalPercent = (cardType) => {
    const total = handleGetTotal();
    const validateMinimunTotal = handleTotalExceedsMinimunTotal();
    let baseComissionWithOutIVA = 0;
    let toDepositToAgency = 0;
    let totalExceeds = 0;

    if (validateMinimunTotal) {
      totalExceeds = handleNewTotalExceeds();
    } else {
      totalExceeds = total;
    }

    let totalAmountToCharge = roundValue(totalExceeds);

    while (totalExceeds > toDepositToAgency) {
      baseComissionWithOutIVA =
        getBaseComission(cardType) * roundValue(totalAmountToCharge);
      toDepositToAgency = roundValue(
        totalAmountToCharge - baseComissionWithOutIVA * IVA,
      );
      const difference = totalExceeds - toDepositToAgency;
      totalAmountToCharge += difference;
    }

    const newMinumun = roundValue(
      ((totalAmountToCharge - totalExceeds) / totalExceeds) * 100,
    );

    return newMinumun;
  };

  const handleExtraValidation = (formData) => {
    const validateTotal = handleValidateTotal();
    const validateMinimunTotal = handleTotalExceedsMinimunTotal();

    if (!validateTotal) {
      return toggleWarningTotal();
    }

    if (type === 'sales' && validateMinimunTotal) {
      return toggleMinimunTotalWarning(formData);
    }

    handleCreatePaymentOrder(formData);
  };

  const handleCreatePaymentOrder = (formData) => {
    Object.keys(formData).forEach(
      (key) =>
        (formData[key] = formData[key] ? formData[key] : undefined),
    );
    let cleanValues = {};
    cleanValues = {
      ...formData,
      products: payItems,
      clientID: getNested(undefined, formData, 'clientAccount'),
      createdBy: user.name,
      type: selectedOrderType,
    };
    if (agencyDetails?.id === 49 || agencyID === 49) {
      cleanValues.number = '3338148944';
      cleanValues.email = 'pedro.romero@clearcheck.us';
      cleanValues.type = 'sales';
    } else {
      cleanValues.agencyID = agencySelected.id;
    }
    createPaymentOrder(cleanValues);
  };

  const renderLimitMinAmount = () => {
    return (
      <Modal
        open={showWarningTotal}
        type="warning"
        closeModal={toggleWarningTotal}
        dialogText="El precio total de la órden debe de ser mayor o igual a $11.00 pesos"
        actionButtonText="Aceptar"
        onClick={toggleWarningTotal}
      />
    );
  };

  const renderMinimunTotalWarning = () => {
    return (
      <Modal
        open={showMinimunTotalWarning}
        type="warning"
        closeModal={toggleMinimunTotalWarning}
        dialogText={
          <>
            <Typography variant="body2" gutterBottom>
              {`Por política de la agencia el pago con tarjeta de crédito o débito en montos superiores a ${formatNumber(
                minimumTotal,
              )} pesos el cliente deberá absorber la comisión por el uso de la terminal bancaria. Esta cantidad se le cobrará a tu cliente de manera automática a la hora de realizar su pago en línea.`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Las comisiones que se le cobrarán al cliente por el uso
              de la terminal son las siguientes:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">
                  Visa / Mastercard (crédito y débito):&nbsp;
                  {getNewMinimunTotalPercent('visa')}%
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Amex:&nbsp;
                  {getNewMinimunTotalPercent('american-express')}%
                </Typography>
              </li>
            </ul>
          </>
        }
        actionButtonText="Aceptar"
        onClick={() => {
          toggleMinimunTotalWarning();
          handleCreatePaymentOrder(localFormData);
        }}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = paymentOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = paymentOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderPayItems = () => {
    return payItems.map((item, idx) => {
      return (
        <React.Fragment key={`payment-${item}-${idx}`}>
          <Grid item xs={12} sm={6}>
            <Input
              required
              label={'Concepto'}
              className={classes.textField}
              value={item.concepto}
              onChange={(e) =>
                handleEditItemConcepto(idx, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiCurrencyFormat
              required
              name="price"
              label="Precio con IVA"
              value={item.total}
              onChange={(value) => handleEditItemTotal(idx, value)}
              className={classes.textFieldAddProducts}
            />
            <IconButton
              aria-label="add"
              className={classes.margin}
              onClick={handleCount}
            >
              <Add fontSize="inherit" />
            </IconButton>
            {payItems.length > 1 ? (
              <IconButton
                aria-label="Delete"
                className={classes.margin}
                onClick={() => handleDelete(idx)}
              >
                <Delete fontSize="inherit" />
              </IconButton>
            ) : null}
          </Grid>
        </React.Fragment>
      );
    });
  };

  return (
    <Paper className={classes.paper}>
      {renderLimitMinAmount()}
      {renderMinimunTotalWarning()}
      {renderFeedBackModal()}
      <form
        onSubmit={handleSubmit(handleExtraValidation)}
        noValidate
        autoComplete="off"
      >
        <ShouldItRender
          locationPage={props.history.location.pathname}
          action="add"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6">Agencia</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MUIRHFAutocompleteAgencies
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
                getAllInfo={(newValue) => {
                  setAgencySelected(newValue);
                }}
              />
            </Grid>
          </Grid>
        </ShouldItRender>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">
              Información de la orden
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="reference"
              label={'Id de la orden'}
              className={classes.textField}
              error={Boolean(errors.reference)}
              helperText="Introduce el número de referencia"
            />
          </Grid>
          {(role === 'Super Admin' &&
            agencySelected?.provisionalPayments) ||
          (role !== 'Super Admin' &&
            agencyDetails?.provisionalPayments) ? (
            <Grid item xs={12} sm={6}>
              <FormGroup className={classes.formGroupSwitch}>
                <FormControlLabel
                  control={
                    <Switch
                      className={classes.switch}
                      color="primary"
                      name="provisional"
                      inputRef={register}
                    />
                  }
                  label="Orden con pago provisional"
                />
              </FormGroup>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <Select
              size={'medium'}
              align={'left'}
              name="orderType"
              label={'Tipo de orden'}
              className={classes.borderInput}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setOrderType(e.target.value);
              }}
              value={selectedOrderType}
              displayEmpty 
              placeholder="Selecciona un tipo de orden"
            >
              <MenuItem value=''>Selecciona un tipo de orden</MenuItem>
              {typeOfOrders.map((typeOrder, index) => (
                <MenuItem key={index} value={typeOrder}>{typeOfPromotionMap(typeOrder)}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="clientAccount"
              label={'Cuenta Cliente (ID de Cliente)'}
              className={classes.textField}
              error={!!errors.clientAccount}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">
              Información del cliente
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
                pattern: /(\w.+\s).+/,
              })}
              name="name"
              label="Nombre o razón social"
              className={classes.textField}
              error={Boolean(errors.name)}
              helperText="Introduce el nombre y apellido del cliente"
            />
          </Grid>
          <ShouldItRender
            should={!(agencyDetails?.id === 49 || agencyID === 49)}
          >
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="number"
                label="Teléfono"
                className={classes.textField}
                error={Boolean(errors.number)}
                helperText="introduce el teléfono del usuario"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                  pattern: emailRegex,
                })}
                name="email"
                label="Correo"
                className={classes.textField}
                error={Boolean(errors.email)}
                helperText="Introduce la dirección de correo electrónico"
              />
            </Grid>
          </ShouldItRender>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6" gutterBottom>
              {'Productos'}
            </Typography>
          </Grid>
          {renderPayItems()}
        </Grid>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={paymentOrders.isLoading}
          >
            Guardar orden de pago
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const mapStateToProps = createStructuredSelector({
  paymentOrders: selectPaymentOrders,
  agencyDetails: makeSelectAgencyDetailsFromState,
  role: makeSelectUserRoleFromState,
  agencies: makeSelectAgenciesFromdb,
});

const mapDispatchToProps = {
  createPaymentOrder: createPaymentOrder,
  closeModals,
  getAgency,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(AddPaymentOrder),
  ),
);
