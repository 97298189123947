import {
  asesorRoles,
  partsAsesorRoles,
  salesAdvisorRoles,
} from '../../config/roles';
import api from '../../config/api';
import { call, put, select } from 'redux-saga/effects';
import {
  getPaymentDetailFail,
  getPaymentDetailSuccess,
  getPaymentsCreditOrdersFail,
  getPaymentsCreditOrdersSuccess,
  getPaymentsFail,
  getPaymentsForDownloadFail,
  getPaymentsForDownloadSuccess,
  getPaymentsTPVForDownloadSuccess,
  getPaymentsTPVForDownloadFail,
  getPaymentsOrdersFail,
  getPaymentsOrdersSuccess,
  getPaymentsSuccess,
  getPaymentsSPEISuccess,
  getPaymentsSPEIFail,
  searchInPaymentsFail,
  searchInPaymentsSuccess,
  sendPaymentReceiptFail,
  sendPaymentReceiptSuccess,
  validatePaymentFail,
  validatePaymentSuccess,
  getPdfDataSuccess,
  getPdfDataFail,
  statusPaymentSuccess,
  statusPaymentFail,
  getTPVPaymentsSuccess,
  getTPVFiservPaymentsFail,
  getTPVFiservPaymentsSuccess,
  getTPVPaymentsFail,
  getPaymentsCompleteForDownloadSuccess,
  getPaymentsCompleteForDownloadFail,
} from 'app/features/payments/paymentsSlice';
import {
  makeSelectAgencyId,
  makeSelectUserId,
  makeSelectUserRole,
} from '../auth/selectors.js';
import { formatDatesForFilter, formatDatesForFilterTimeZone } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import moment from 'moment';
import { requestHandler } from '../../services/requestHandler';

import { formatDate } from '../../utils';
const order = {
  0: {
    id: 'serviceOrderID',
    orderRoute: '/serviceOrder',
  },
  1: {
    id: 'paymentOrderID',
    orderRoute: '/paymentOrder',
  },
};

const API_URL = api();

export function* getTPVPaymentsSaga(action) {
  try {
    let page = 0;
    let pageSize = 20;
    let filtered;
    if (action) {
      page = action.payload.page;
      pageSize = action.payload.pageSize;
      filtered = action.payload.filtered;
    }
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      search: [],
      filter: [],
    };
    const tpvPayments = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentTerminal/getAll',
      data,
    });
    tpvPayments.pages = yield call(
      Math.ceil,
      tpvPayments.terminals.length / pageSize,
    );
    yield put(getTPVPaymentsSuccess({ tpvPayments }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getTPVPaymentsFail({ message }));
    console.log('error in TPVPayments saga', e);
  }
}

//TODO: Quedamos adecuando la saga que tenemos en el modulo de stand Alone aqui para que funcione al cambarse de pestaña en el modulo de pagos
export function* getAllPaymentsStandAloneSagaP(action) {
  const { page, pageSize, sorted, filtered } = action.payload;
  try {
    let offset = 0;
    let fromDate = action.payload.fromDate
      ? moment(action.payload.fromDate).format('YYYY-MM-DDT00:00:00')
      : '';
    let toDate = action.payload.toDate
      ? moment(action.payload.toDate).format('YYYY-MM-DDT23:59:59')
      : '';
    let ordering = 'DESC';
    let field = 'transactionDate';
    if (sorted.length > 0) {
      field = sorted[0].id;
      ordering = sorted[0].desc ? 'DESC' : 'ASC';
    }

    if (page > 0) {
      offset = pageSize * page;
    }
    let where = {};
    const data = {
      where,
      agencies: {
        where: {},
      },
      pagination: {
        limit: pageSize,
        offset,
      },
      ordering: {
        field: field,
        direction: ordering,
      },
      fromDate,
      toDate,
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'id') data.where['id'] = filter.value;
        else if (filter.id === 'agencyName')
          data.agencies.where['name'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'locationID')
          data.where['locationID'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'transactionTime')
          data.where['transactionTime'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'transactionAmount')
          data.where['transactionAmount'] = filter.value;
        else if (filter.id === 'transactionDate')
          data.where['transactionDate'] = filter.value;
        else data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    const deposits = yield call(requestHandler, {
      method: 'POST',
      path: '/payments-standalone/getAll',
      data,
    });
    yield put(getTPVFiservPaymentsSuccess({ deposits }));
  } catch (e) {
    yield put(getTPVFiservPaymentsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

// ODS
export function* getPaymentsSaga(action) {
  try {
    const {
      firstDate,
      secondDate,
      page,
      pageSize,
      filtered,
      sorted,
      userAgencyData,
      agenciesList,
    } = action.payload;
    
    let ordering = 'DESC';
    if (sorted.length > 0 && sorted[0].id === 'date') {
      ordering = sorted[0].desc ? 'DESC' : 'ASC';
    }
    const data = {
      where: {
        status: { $or: ['DONE', 'REFUND'] },
        serviceOrderID: { $not: null },
      },
      serviceOrders: {
        invoiceProfiles: {
          where: {},
        },
        payments: {
          where: {},
        },
        agencies: {
          where: agenciesList.length > 0 ? { id: agenciesList } : {},
        },
        where: {},
      },
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['date', ordering]],
      },
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'orderID')
          data.serviceOrders.where['orderID'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'agencyName')
          data.serviceOrders.agencies.where['name'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'id') data.where['id'] = filter.value;
        else if (filter.id === 'origin')
          if (filter.value === 'kiosko') {
            data.where['origin'] = {
              $or: [`kiosko`, `pos`],
            };
          } else {
            data.where['origin'] = {
              $iLike: `%${filter.value}%`,
            };
          }
        else if (filter.id === 'clientFullName')
          data.serviceOrders.invoiceProfiles.where['fullName'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'type');
        else if (filter.id === 'paymentMethod')
          data.where['type'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'billPocketID')
          data.where['billPocketID'] = filter.value;
        else if (filter.id === 'authNumber')
          data.where['authNumber'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'transactionId') {
          if (userAgencyData?.paymentAggregators === 'billpocket') {
            data.where['billPocketID'] = filter.value;
          } else {
            data.where['authNumber'] = {
              $iLike: `%${filter.value}%`,
            };
          }
        } else if (filter.id === 'months')
          data.where['months'] = filter.value;
        else if (filter.id === 'date')
          data.where['paymentDate'] = formatDatesForFilterTimeZone(
            filter.value,
          );
        else data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    if (firstDate && secondDate) {
      data.where.paymentDate = formatDatesForFilterTimeZone(
        firstDate,
        secondDate,
      );
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    payments.pages = yield call(Math.ceil, payments.count / pageSize);
    yield put(getPaymentsSuccess({ payments, data }));
  } catch (e) {
    yield put(getPaymentsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

// ODP
export function* getPaymentsOrdersSaga(action) {
  try {
    const {
      firstDate,
      secondDate,
      page,
      pageSize,
      filtered,
      tabSelected,
      sorted,
      userAgencyData,
      agenciesList,
    } = action.payload;
        const userRole = yield select(makeSelectUserRole());
    const adminID = yield select(makeSelectUserId());
    const agencyID = yield select(makeSelectAgencyId());
    let ordering = 'DESC';
    if (sorted.length > 0 && sorted[0].id === 'date') {
      ordering = sorted[0].desc ? 'DESC' : 'ASC';
    }
    const data = {
      paymentOrders: {
        agencies: {
          where: {},
        },
        where: {},
      },
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['date', ordering]],
      },
      where: {
        status: { $or: ['DONE', 'REFUND'] },
        paymentOrderID: { $not: null },
      },
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'orderID')
          data.paymentOrders.where['reference'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'agencyName')
          data.paymentOrders.agencies.where['name'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'id') data.where['id'] = filter.value;
        else if (filter.id === 'clientFullName')
          data.paymentOrders.where['nombre'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'invoiceNumber');
        else if (filter.id === 'paymentMethod')
          data.where['type'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'billPocketID')
          data.where['billPocketID'] = filter.value;
        else if (filter.id === 'authNumber')
          data.where['authNumber'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'transactionId') {
          if (userAgencyData?.paymentAggregators === 'billpocket') {
            data.where['billPocketID'] = filter.value;
          } else {
            data.where['authNumber'] = {
              $iLike: `%${filter.value}%`,
            };
          }
        } else if (filter.id === 'months')
          data.where['months'] = filter.value;
        else if (filter.id === 'type')
          data.paymentOrders.where = {
            ...data.paymentOrders.where,
            type: filter.value,
          };
        else if (filter.id === 'date')
          data.where['date'] = formatDatesForFilter(filter.value);
        else
          data.where[filter.id] = {
            $iLike: `%${filter.value.includes('Apa')
              ? 'pulled_apart'
              : filter.value
              }%`,
          };
      });
    }
    if (agenciesList?.length > 0) {
      data.paymentOrders.agencies.where['id'] = agenciesList;
    }
    if (firstDate && secondDate)
      data.where.date = formatDatesForFilter(firstDate, secondDate);
    data.paymentOrders.agencies = agencyID
      ? {
        where: {
          ...data.paymentOrders.agencies.where,
          id: agencyID,
        },
      }
      : { where: { ...data.paymentOrders.agencies.where } };

    switch (userRole) {
      case 'Service Manager':
        data.paymentOrders.where = {
          ...data.paymentOrders.where,
          type: { $or: ['maintenance', 'parts'] },
        };
        break;
      case 'Sales Manager':
        data.paymentOrders.where = {
          ...data.paymentOrders.where,
          type: 'sales',
        };
        break;
      case 'Parts Manager':
        data.paymentOrders.where = {
          ...data.paymentOrders.where,
          type: 'parts',
        };
        break;
      default:
        break;
    }
    if (tabSelected === 1) {
      data.paymentOrders.where.advance = null;
    } else if (tabSelected === 2) {
      data.paymentOrders.where.provisional = true;
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    payments.pages = yield call(Math.ceil, payments.count / pageSize);
    yield put(getPaymentsOrdersSuccess({ payments, data }));
  } catch (e) {
    yield put(getPaymentsOrdersFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentsCreditOrdersSaga(action) {
  try {
    const { firstDate, secondDate, sorted } = action.payload;
    const agencyID = yield select(makeSelectAgencyId());

    let ordering = 'DESC';
    if (sorted.length > 0 && sorted[0].id === 'date') {
      ordering = sorted[0].desc ? 'DESC' : 'ASC';
    }
    const data = {
      creditOrders: {
        where: {},
        agencies: true,
      },
      where: {
        status: { $or: ['DONE', 'REFUND'] },
        creditOrderID: { $not: null },
      },
      pagination: {
        order: [['date', ordering]],
      },
    };
    if (firstDate && secondDate)
      data.where.createdAt = formatDatesForFilter(
        firstDate,
        secondDate,
      );
    data.creditOrders.agencies = agencyID
      ? { where: { id: agencyID } }
      : true;

    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    yield put(getPaymentsCreditOrdersSuccess({ payments }));
  } catch (e) {
    yield put(getPaymentsCreditOrdersFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentsSPEISaga(action) {
  try {
    const { firstDate, secondDate, page, pageSize, filtered } =
      action.payload;
    const data = {
      where: {
        type: 'SPEI',
        status: 'Sin Aplicar',
        serviceOrderID: { $not: null },
        updatedAt: {
          $gt: moment().subtract(4, 'months').startOf('day'),
        },
      },
      serviceOrders: {
        invoiceProfiles: {
          where: {},
        },
        agencies: {
          where: {},
        },
        where: {},
      },
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['date', 'DESC']],
      },
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'orderID')
          data.serviceOrders.where['orderID'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'agencyName')
          data.serviceOrders.agencies.where['name'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'clientFullName')
          data.serviceOrders.invoiceProfiles.where['fullName'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'paymentMethod')
          data.where['type'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'billPocketID')
          data.where['billPocketID'] = filter.value;
        else if (filter.id === 'months')
          data.where['months'] = filter.value;
        else if (filter.id === 'date')
          data.where['date'] = formatDatesForFilter(filter.value);
        else data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    if (firstDate && secondDate)
      data.where.date = formatDatesForFilter(firstDate, secondDate);
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    payments.pages = yield call(Math.ceil, payments.count / pageSize);
    yield put(getPaymentsSPEISuccess({ payments, data }));
  } catch (e) {
    yield put(getPaymentsSPEIFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentsForDownloadSaga(action) {
  try {
    let { data, type, wheres } = action.payload;
    let stringWhere = ``;
    if (wheres) {
      wheres.forEach((element) => {
        switch (element.id) {
          case 'agencyName':
            stringWhere += ` AND t0.name ILIKE '%${element.value}%'`;
            break;
          case 'clientFullName':
            if (type === 'odp') {
              stringWhere += ` AND t1."nombre" ILIKE '%${element.value}%'`;
            } else if ('ods') {
              stringWhere += ` AND t3."fullName" ILIKE '%${element.value}%'`;
            }
            break;
          case 'billPocketID':
            stringWhere += ` AND t2."billPocketID" ILIKE '%${element.value}%'`;
            break;
          case 'invoiceNumber':
            if (type === 'ods') {
              stringWhere += ` AND t2."invoiceNumber" ILIKE '%${element.value}%'`;
            }
            break;
          case 'orderID':
            if (type === 'odp') {
              stringWhere += ` AND t1."reference" ILIKE '%${element.value}%'`;
            } else if ('ods') {
              stringWhere += ` AND t1."orderID" ILIKE '%${element.value}%'`;
            }
            break;
          case 'paymentMethod':
            stringWhere += ` AND t2."type" ILIKE '%${element.value}%'`;
            break;
          case 'months':
            stringWhere += ` AND t2."months" = '${element.value}'`;
            break;
          case 'type':
            if (type === 'odp') {
              stringWhere += ` AND t1."type" ILIKE '%${element.value}%'`;
            }
            break;
          default:
            break;
        }
        data.wheres = stringWhere;
      });
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/export-report-${type}`,
      data,
    });
    yield put(getPaymentsForDownloadSuccess({ payments }));
  } catch (e) {
    yield put(getPaymentsForDownloadFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}
export function* getPaymentsTPVForDownloadSaga(action) {
  try {
    let { data, type, wheres } = action.payload;
    let stringWhere = ``;
    if (wheres) {
      wheres.forEach((element) => {
        switch (element.id) {
          case 'agencyName':
            stringWhere += ` AND t0.name ILIKE '%${element.value}%'`;
            break;
          case 'id':
            stringWhere += ` AND t1."id" = '${element.value}'`;
            break;
          case 'transactionTime':
            stringWhere += ` AND t1."transactionTime" ILIKE '%${element.value}%'`;
            break;
          case 'locationID':
            stringWhere += ` AND t1."locationID" ILIKE '%${element.value}%'`;
            break;
          case 'transactionAmount':
            stringWhere += ` AND t1."transactionAmount" ILIKE '%${element.value}%'`;
            break;
          case 'transactionDate':
            stringWhere += ` AND t2."transactionDate" = '${element.value}'`;
            break;
          default:
            break;
        }
        data.wheres = stringWhere;
      });
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: `/payments-standalone/export-report`,
      data,
    });
    yield put(getPaymentsTPVForDownloadSuccess({ payments }));
  } catch (e) {
    yield put(getPaymentsTPVForDownloadFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentsCompleteForDownloadSaga(action) {
  try {
    let { data, wheres } = action.payload;
    let strignWhereODS = '';
    let strignWhereODP = ``;
    if (wheres) {
      wheres.forEach((element) => {
        switch (element.id) {
          case 'agencyName':
            strignWhereODS += ` AND t0.name ILIKE '%${element.value}%'`;
            strignWhereODP += ` AND t0.name ILIKE '%${element.value}%'`;
            break;
          case 'clientFullName':
            strignWhereODS += ` AND t3."fullName" ILIKE '%${element.value}%'`;
            strignWhereODP += ` AND t1."nombre" ILIKE '%${element.value}%'`;
            break;
          case 'billPocketID':
            strignWhereODS += ` AND t2."billPocketID" ILIKE '%${element.value}%'`;
            strignWhereODP += ` AND t2."billPocketID" ILIKE '%${element.value}%'`;
            break;
          case 'invoiceNumber':
            strignWhereODS += ` AND t2."invoiceNumber" ILIKE '%${element.value}%'`;
            break;
          case 'orderID':
            strignWhereODS += ` AND t1."orderID" ILIKE '%${element.value}%'`;
            strignWhereODP += ` AND t1."reference" ILIKE '%${element.value}%'`;
            break;
          case 'paymentMethod':
            strignWhereODS += ` AND t2."type" ILIKE '%${element.value}%'`;
            strignWhereODP += ` AND t2."type" ILIKE '%${element.value}%'`;
            break;
          case 'months':
            strignWhereODS += ` AND t2."months" = '${element.value}'`;
            strignWhereODP += ` AND t2."months" = '${element.value}'`;
            break;
          case 'type':
            strignWhereODP += ` AND t1."type" ILIKE '%${element.value}%'`;
            break;
          default:
            break;
        }
        data.wheresOBJ.strignWhereODS = strignWhereODS;
        data.wheresOBJ.strignWhereODP = strignWhereODP;
      });
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/export-report-complate',
      data,
    });
    yield put(getPaymentsCompleteForDownloadSuccess({ payments }));
  } catch (error) {
    yield put(
      getPaymentsCompleteForDownloadFail(
        'getPaymentsCompleteForDownloadSaga',
      ),
    );
    console.log(
      'error in get payments complete for download saga',
      error,
    );
  }
}

export function* searchInPaymentsSaga(action) {
  try {
    const { cardHolder, paymentProcessor, total } = action.payload;
    const data = {
      where: {
        total: total || undefined,
        status: { $or: ['DONE', 'REFUND'] },
      },
      serviceOrders: {
        invoiceProfiles: {
          options: {
            attributes: ['fullName'],
          },
        },
        agencies: {
          options: {
            attributes: ['name'],
          },
        },
      },
      paymentOrders: {
        agencies: {
          options: {
            attributes: ['name'],
          },
        },
      },
      creditOrders: {
        agencies: {
          options: {
            attributes: ['name'],
          },
        },
      },
      pagination: {
        order: [['updatedAt', 'DESC']],
      },
    };
    if (cardHolder) {
      data.where = {
        ...data.where,
        $or: [
          {
            cardHolder: { $iLike: `%${cardHolder}%` },
          },
        ],
      };
    }
    if (paymentProcessor) {
      data.where = {
        ...data.where,
        [paymentProcessor]: { $not: null },
      };
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    yield put(searchInPaymentsSuccess({ payments }));
  } catch (e) {
    yield put(searchInPaymentsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentDetailSaga(action) {
  try {
    const { paymentID } = action.payload;
    const data = {
      where: { id: paymentID },
      serviceOrders: {
        agencies: {
          surTaxes: true,
        },
        invoiceProfiles: true,
        paymentOrders: {
          options: {
            attributes: ['total'],
          },
        },
        surTaxes: {
          options: {
            attributes: ['3', '6', '9', '12', '18'],
          },
        },
        AMEXSurTaxes: {
          options: {
            attributes: ['3', '6', '9', '12', '18'],
          },
        },
        detailPromotionByAgencies: {
          options: {
            attributes: [
              'id',
              'surTaxID',
              'amexSurTaxID',
              'agencyID',
              'serviceOrderID',
              'paymentOrderID',
            ],
          },
        },
      },
      paymentOrders: {
        agencies: {
          surTaxes: true,
        },
        surTaxes: {
          options: {
            attributes: [
              'id',
              '3',
              '6',
              '9',
              '12',
              '18',
              'typeOfPromotion',
            ],
          },
        },
        AMEXSurTaxes: {
          options: {
            attributes: [
              'id',
              '3',
              '6',
              '9',
              '12',
              '18',
              'typeOfPromotion',
            ],
          },
        },
        detailPromotionByAgencies: {
          options: {
            attributes: [
              'id',
              'surTaxID',
              'amexSurTaxID',
              'agencyID',
              'serviceOrderID',
              'paymentOrderID',
            ],
          },
        },
      },
      creditOrders: true,
      invoiceDetails: true,
    };
    const payment = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getOne',
      data,
    });

    yield put(
      getLogs({
        object: 'payments',
        objectID: paymentID,
      }),
    );
    yield put(getPaymentDetailSuccess({ payment }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener información pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentDetailFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* sendPaymentReceiptSaga(action) {
  try {
    const { orderType, email, orderID, paymentID } = action.payload;
    const data = {
      email: email ? email : undefined,
      paymentID,
      type: 'receipt',
    };
    if (orderType === 'payment') {
      yield call(requestHandler, {
        method: 'POST',
        path: `/payment/refund-mail`,
        data,
      });
    } else {
      data[order[orderType]['id']] = orderID;
      yield call(requestHandler, {
        method: 'POST',
        path: `${order[orderType]['orderRoute']}/mail`,
        data,
      });
    }
    const message = {
      title: '¡Enviado!',
      desc: `Correo enviado correctamente a ${email}`,
    };
    yield put(sendPaymentReceiptSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el correo.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(sendPaymentReceiptFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* sendInvoiceReceiptSaga(action) {
  try {
    const { phone, paymentID } = action.payload;
    const data = {
      phone,
      paymentID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: `/payment/resend-invoice-receipt`,
      data,
    });
    const message = {
      title: '¡Enviado!',
      desc: `mensaje enviado correctamente a ${phone}`,
    };
    yield put(sendPaymentReceiptSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el mensaje.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(sendPaymentReceiptFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* validatePaymentSaga(action) {
  const { invoiceNumber, id } = action.payload;
  try {
    const data = {
      ...action.payload,
    };
    const payment = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/addInvoiceNumber`,
      data,
    });
    const message = {
      title: '¡Pago asociado correctamente!',
      desc: `El pago ha sido validado correctamente con en número de factura: ${invoiceNumber}`,
    };
    yield put(validatePaymentSuccess({ message, payment }));
    yield call(getPaymentDetailSaga, { payload: { paymentID: id } });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba validar el pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(validatePaymentFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* pdfDataSaga(action) {
  try {
    const { id, order } = action.payload;
    const pdfInfo = yield call(requestHandler, {
      method: 'GET',
      path: `/${order}/pdfInvoice?paymentID=${id}`,
    });

    let products = [];
    let data;
    if (pdfInfo) {
      const { dataInfo, serviceOrder, paymentOrder } = pdfInfo;
      const pdfData = dataInfo;
      if (order === 'paymentOrder') {
        if (
          Array.isArray(pdfData.operations) &&
          pdfData.operations.length > 0
        ) {
          products = pdfData.operations.map((el) => ({
            quantity: 1,
            description: el.concepto ? el.concepto : '-',
            tax: 0,
            price: el.total ? el.total : 0,
          }));
        } else {
          products = [
            {
              /* prettier-ignore */
              'quantity': '1',
              /* prettier-ignore */
              'description': `Servicio de taller ${pdfData?.agencyName}`,
              /* prettier-ignore */
              'tax': 0,
              /* prettier-ignore */
              'price': pdfData?.orderTotal,
            },
          ];
        }
        if (pdfData) {
          data = {
            /* prettier-ignore */
            'documentTitle': pdfData?.agencyName ? pdfData?.agencyName : '-',
            /* prettier-ignore */
            'currency': 'MXN',
            /* prettier-ignore */
            'taxNotation': 'vat', //or gst
            /* prettier-ignore */
            'marginTop': 35,
            /* prettier-ignore */
            'marginRight': 50,
            /* prettier-ignore */
            'marginLeft': 50,
            /* prettier-ignore */
            'marginBottom': 10,
            /* prettier-ignore */
            'logo': pdfData?.agencyLogo ? pdfData?.agencyLogo : null, //or base64
            /* prettier-ignore */
            "background": "https://s3.us-east-2.amazonaws.com/karlov2/agencyLogos/16285507684029854125827828999.jpeg",
            //'logoExtension': 'png', //only when logo is base64
            /* prettier-ignore */
            'sender': {
              'company': pdfData?.agencyBusinessName ? pdfData?.agencyBusinessName : ' ',
              'address': `Dirección: ${pdfData?.agencyAddress ? pdfData?.agencyAddress : '-'}`,
              'zip': `Número de teléfono : ${pdfData?.agencyPhone ? pdfData?.agencyPhone : '-'} `,
              'city': ` `,
              'country': ` `,
            },
            /* prettier-ignore */
            'client': {
              'company': `Orden de pago: ${paymentOrder?.orderID ? paymentOrder?.orderID : '-'}`,
              'address': `Fecha de pago: ${pdfData?.paymentDate ? formatDate(pdfData?.paymentDate) : '-'}`,
              'zip': `Método de pago: ${pdfData?.paymentForm ? pdfData?.paymentForm : '-'}`,
              'city': `Forma de pago : ${pdfData?.paymentMethod ? pdfData?.paymentMethod : '-'}`,
              'country': `Transacción No. : ${pdfData?.noPayment ? pdfData?.noPayment : '-'}`,
              'custom1': `Factura No. : ${pdfData?.noOrder ? pdfData?.noOrder : '-'}`,
            },
            /* prettier-ignore */
            'invoiceNumber': pdfData?.clientName ? pdfData?.clientName : '-',
            /* prettier-ignore */
            // 'invoiceDate':
            /* prettier-ignore */
            'products': products,
            //Used for translating the headers to your preferred language
            //Defaults to English. Below example is translated to Dutch
            /* prettier-ignore */
            'translate': {
              'invoiceNumber': 'Cliente',
              'invoiceDate': ' ',
              'products': 'Productos',
              'quantity': 'Cantidad',
              'price': 'Precio',
              'subtotal': 'Subtotal',
              'total': 'Total'
            },
            /* prettier-ignore */
            'bottomNotice': `Transacción ejecutada a través de: ${paymentOrder?.agency?.bpAgencyName ? serviceOrder?.agency?.bpAgencyName : '-'}
            | Afiliación: ${serviceOrder?.agency?.afiliationNumber ? serviceOrder?.agency?.afiliationNumber : '-'} | Operación: ${pdfData?.billPocketID ? pdfData?.billPocketID : '-'}
            `,
          };
        }
      }
      if (order === 'serviceOrder') {
        if (
          Array.isArray(pdfData.operations) &&
          pdfData.operations.length > 0
        ) {
          products = pdfData.operations.map((el) => ({
            quantity: 1,
            description: el.description ? el.description : '-',
            tax: 0,
            price: el.price ? el.price : 0,
          }));
        } else {
          products = [
            {
              /* prettier-ignore */
              'quantity': '1',
              /* prettier-ignore */
              'description': `Servicio de taller ${pdfData?.agencyName}`,
              /* prettier-ignore */
              'tax': 0,
              /* prettier-ignore */
              'price': pdfData?.orderTotal,
            },
          ];
        }
        if (pdfData) {
          data = {
            /* prettier-ignore */
            'documentTitle': pdfData?.agencyName ? pdfData?.agencyName : '-', //Defaults to INVOICE
            /* prettier-ignore */
            'currency': 'MXN',
            /* prettier-ignore */
            'taxNotation': 'vat', //or gst
            /* prettier-ignore */
            'marginTop': 35,
            /* prettier-ignore */
            'marginRight': 50,
            /* prettier-ignore */
            'marginLeft': 50,
            /* prettier-ignore */
            'marginBottom': 10,
            /* prettier-ignore */
            'logo': pdfData?.agencyLogo ? pdfData?.agencyLogo : null, //or base64
            /* prettier-ignore */
            "background": "https://s3.us-east-2.amazonaws.com/karlov2/agencyLogos/16285507684029854125827828999.jpeg",
            //'logoExtension': 'png', //only when logo is base64
            /* prettier-ignore */
            'sender': {
              'company': pdfData?.agencyBusinessName ? pdfData?.agencyBusinessName : ' ',
              'city': `Dirección: ${pdfData?.agencyAddress ? pdfData?.agencyAddress : '-'}`,
              'address': `Número de teléfono : ${pdfData?.agencyPhone ? pdfData?.agencyPhone : '-'} `,
              'zip': ` `,
              'country': ` `,
            },
            /* prettier-ignore */
            'client': {
              'company': `Orden de servicio: ${serviceOrder?.orderID ? pdfData?.orderID : '-'}`,
              'address': `Fecha de pago: ${pdfData?.paymentDate ? formatDate(pdfData?.paymentDate) : '--'}`,
              'zip': `Método de pago: ${pdfData?.paymentForm ? pdfData?.paymentForm : '-'}`,
              'city': `Forma de pago : ${pdfData?.paymentMethod ? pdfData?.paymentMethod : '-'}`,
              'country': `Transacción No. : ${pdfData?.noPayment ? pdfData?.noPayment : '-'}`,
              'custom1': `Factura No. : ${pdfData?.noOrder ? pdfData?.noOrder : '-'}`,
            },
            /* prettier-ignore */
            'invoiceNumber': pdfData?.clientName ? pdfData?.clientName : '-',
            /* prettier-ignore */
            'invoiceDate': `${pdfData?.vehicleBrand ? pdfData?.vehicleBrand : '-'} ${pdfData?.vehicleKind ? pdfData?.vehicleKind : '-'} ${serviceOrder?.vehicle?.year ? serviceOrder?.vehicle?.year : '-'}`,
            /* prettier-ignore */
            'products': products,
            //Used for translating the headers to your preferred language
            //Defaults to English. Below example is translated to Dutch
            /* prettier-ignore */
            'translate': {
              'invoiceNumber': 'Cliente',
              'invoiceDate': 'Vehículo',
              'products': 'Productos',
              'quantity': 'Cantidad',
              'price': 'Precio',
              'subtotal': 'Subtotal',
              'total': 'Total'
            },
            /* prettier-ignore */
            'bottomNotice': `Transacción ejecutada a través de: ${serviceOrder?.agency?.bpAgencyName ? serviceOrder?.agency?.bpAgencyName : '-'}
            | Afiliación: ${serviceOrder?.agency?.afiliationNumber ? serviceOrder?.agency?.afiliationNumber : '-'} | Operación: ${pdfData?.billPocketID ? pdfData?.billPocketID : '-'}
            `,
          };
        }
      }
    }
    yield put(getPdfDataSuccess({ pdfInfo }));
  } catch (error) {
    yield put(getPdfDataFail());
    console.log('error sending pdf data', error);
  }
}

export function* updateStatusPaymentSaga(action) {
  const { status, paymentID } = action.payload;
  try {
    let newStatus;
    status === 'DONE' ? (newStatus = 'REFUND') : (newStatus = 'DONE');
    const data = {
      id: paymentID,
      status: newStatus,
    };
    const updateStatus = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/update',
      data,
    });
    const message = {
      title: 'El estado de la orden fue actualizado',
      desc: 'El estado de la orden fue cambiado, recuerde que si lo desea puede revertir esta acción.',
    };
    yield put(statusPaymentSuccess({ message }));
  } catch (error) {
    const message = {
      title: 'Error al actualizar el estado',
      desc: 'Se produjo un error al cambiar el estado de la orden, intente más tarde o contacte al administrador.',
    };
    yield put(statusPaymentFail({ message }));
    console.log(error);
  }
}
